exports.components = {
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-cli-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/cli.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-cli-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-configuration-files-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/configuration-files.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-configuration-files-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-custom-templates-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/custom-templates.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-custom-templates-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-custom-transformations-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/custom-transformations.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-custom-transformations-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-ecosystem-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/ecosystem.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-ecosystem-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-getting-started-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/getting-started.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-getting-started-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-jest-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/jest.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-jest-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-migrate-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/migrate.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-migrate-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-mocha-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/mocha.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-mocha-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-next-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/next.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-next-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-node-api-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/node-api.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-node-api-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-options-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/options.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-options-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-parcel-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/parcel.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-parcel-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-remix-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/remix.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-remix-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-rollup-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/rollup.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-rollup-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-supporting-svgr-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/supporting-svgr.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-supporting-svgr-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-webpack-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/webpack.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-webpack-mdx" */),
  "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-what-is-svgr-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/doc.js?__contentFilePath=/opt/build/repo/website/pages/docs/what-is-svgr.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-doc-js-content-file-path-opt-build-repo-website-pages-docs-what-is-svgr-mdx" */),
  "component---node-modules-smooth-doc-src-templates-page-js-content-file-path-opt-build-repo-website-node-modules-smooth-doc-pages-404-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/page.js?__contentFilePath=/opt/build/repo/website/node_modules/smooth-doc/pages/404.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-page-js-content-file-path-opt-build-repo-website-node-modules-smooth-doc-pages-404-mdx" */),
  "component---node-modules-smooth-doc-src-templates-page-js-content-file-path-opt-build-repo-website-pages-index-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/page.js?__contentFilePath=/opt/build/repo/website/pages/index.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-page-js-content-file-path-opt-build-repo-website-pages-index-mdx" */),
  "component---node-modules-smooth-doc-src-templates-page-js-content-file-path-opt-build-repo-website-pages-playground-mdx": () => import("./../../../node_modules/smooth-doc/src/templates/page.js?__contentFilePath=/opt/build/repo/website/pages/playground.mdx" /* webpackChunkName: "component---node-modules-smooth-doc-src-templates-page-js-content-file-path-opt-build-repo-website-pages-playground-mdx" */)
}

